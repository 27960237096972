import { Link, NavLink, useLocation } from "@remix-run/react"
import { useState, useEffect } from "react";
import { classNames } from "~/lib/util";
import NetworkSelector from "../network-selector";
import Logo from "./logo"
import { useRdt } from "~/rdt/hooks/useRdt";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { is } from "drizzle-orm";

declare global {
   namespace JSX {
      interface IntrinsicElements {
         'radix-connect-button': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      }
   }
}

export default function Header() {
	const [menuOpen, setMenuOpen] = useState(false);
   const [showingPools, setShowingPools] = useState(false);
   const [showingTokens, setShowingTokens] = useState(false);
   const location = useLocation();
   const rdt = useRdt();

   // set button layout
   useEffect(() => {
      rdt?.buttonApi.setTheme('white');
      rdt?.buttonApi.setMode('light');
   }, []);

	function toggleMenu() {
      setMenuOpen(!menuOpen);
   }

   function closeMenu() {
      if (!menuOpen) {
         return;
      }

      setMenuOpen(false);
   }
	
	return (
    <header className="sticky top-0 z-50 backdrop-blur-sm">
      <nav className="relative flex items-center justify-between px-4 pb-3 pt-6 xl:container xl:mx-auto xl:px-0">
        <button onClick={toggleMenu} className="h-4 w-8 lg:hidden">
          <ul className="flex h-4 flex-col justify-around">
            <li
              className={classNames(
                menuOpen ? "rot45deg" : "",
                "relative h-0.5 bg-white duration-200 ease-in-out dark:bg-primary-100",
              )}
            ></li>
            <li
              className={classNames(
                menuOpen ? "rot-45deg" : "",
                "relative h-0.5 bg-white duration-200 ease-in-out dark:bg-primary-100",
              )}
            ></li>
          </ul>
        </button>

        <Link to="/liquidity/add" className="flex justify-center">
          <Logo />
        </Link>

        <ul
          className={classNames(
            menuOpen ? "absolute left-0 top-20 flex" : "hidden",
            "max-sm:h-screen w-full flex-col max-sm:space-y-4 border-b border-primary-700 bg-primary-500 py-4 text-lg lg:order-none lg:flex lg:w-min lg:flex-row lg:space-y-0 lg:border-0 lg:bg-transparent lg:py-0 lg:text-base",
          )}
        >
          <li>
            <NavLink
              to="/swap"
              onClick={closeMenu}
              className={({ isActive }) =>
                (isActive ? "border-white" : "border-transparent") +
                " mx-4 block w-full border-b-2 pt-0.5 hover:border-white lg:w-min"
              }
            >
              Swap
            </NavLink>
          </li>
          <li
            className="relative mx-4 flex items-center gap-x-1"
            onMouseEnter={() => setShowingPools(true)}
            onMouseLeave={() => setShowingPools(false)}
          >
            <NavLink
              to="/liquidity/add"
              onClick={closeMenu}
              className={({ isActive }) =>
                (isActive ? "border-white" : "border-transparent") +
                " border-b-2 pt-0.5 hover:border-white lg:w-min"
              }
            >
              Pools
            </NavLink>

            {!menuOpen && showingPools ? (
              <>
                <ChevronUpIcon className="ml-1 h-5 w-5" aria-hidden="true" />
                <div className="absolute left-1/2 top-1 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4 pt-5">
                  <div className="relative w-56 shrink rounded-lg bg-white p-2 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                    <Link
                      to="/liquidity/add"
                      onClick={closeMenu}
                      className={"block p-1 hover:text-primary-600"}
                    >
                      Pools
                    </Link>
                    <Link
                      to="/ignition"
                      onClick={closeMenu}
                      className={"block p-1 hover:text-primary-600"}
                    >
                      Project Ignition
                    </Link>
                    <Link
                      to="/liquidity/create"
                      onClick={closeMenu}
                      className={
                        "block border-t border-t-primary-100 p-1 hover:text-primary-600"
                      }
                    >
                      Create new pool
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <ChevronDownIcon
                className="ml-1 hidden h-5 w-5 lg:block"
                aria-hidden="true"
              />
            )}
          </li>
          <li
            className="relative mx-4 flex items-center gap-x-1"
            onMouseEnter={() => setShowingTokens(true)}
            onMouseLeave={() => setShowingTokens(false)}
          >
            <NavLink
              to="/tokens"
              onClick={closeMenu}
              className={({ isActive }) =>
                (isActive ? "border-white" : "border-transparent") +
                " w-min border-b-2 pt-0.5 hover:border-white"
              }
            >
              Tokens
            </NavLink>

            {!menuOpen && showingTokens ? (
              <>
                <ChevronUpIcon className="ml-1 h-5 w-5" aria-hidden="true" />
                <div className="absolute left-1/2 top-1 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4 pt-5">
                  <div className="relative w-56 shrink rounded-lg bg-white p-2 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                    <Link
                      to="/tokens"
                      onClick={closeMenu}
                      className={"block p-1 hover:text-primary-600"}
                    >
                      Tokens
                    </Link>
                    <Link
                      to="/tokens/staking"
                      onClick={closeMenu}
                      className={"block p-1 hover:text-primary-600"}
                    >
                      Staking
                    </Link>
                    <Link
                      to="/launch"
                      onClick={closeMenu}
                      className={
                        "block border-t border-t-primary-100 p-1 hover:text-primary-600"
                      }
                    >
                      Launch new token
                    </Link>
                  </div>
                </div>
              </>
            ) : (
              <ChevronDownIcon
                className="ml-1 hidden h-5 w-5 lg:block"
                aria-hidden="true"
              />
            )}
          </li>
          <li>
            <NavLink
              to="/tokens/staking"
              onClick={closeMenu}
              className={({ isActive }) =>
                (isActive ? "border-white" : "border-transparent") +
                " mx-4 block w-full border-b-2 pt-0.5 hover:border-white lg:hidden lg:w-min"
              }
            >
              Staking
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/liquidity/my"
              onClick={closeMenu}
              className={({ isActive }) =>
                (isActive ? "border-white" : "border-transparent") +
                " mx-4 block w-full border-b-2 pt-0.5 hover:border-white lg:w-min"
              }
            >
              Portfolio
            </NavLink>
          </li>
          {/* <li>
                  <NavLink
                     to="/migrate"
                     onClick={closeMenu}
                     className={({ isActive }) => (isActive ? 'border-white' : 'border-transparent') + ' block w-full lg:w-min whitespace-nowrap mx-4 pt-0.5 border-b-2 hover:border-white'}
                  >
                     Migrate DFP2
                  </NavLink>
               </li> */}
          {/* <li>
                  <NavLink
                     to="/governance"
                     onClick={closeMenu}
                     className={({ isActive }) => (isActive ? 'border-white' : 'border-transparent') + ' block w-full lg:w-min mx-4 pt-0.5 border-b-2 hover:border-white'}
                  >
                     Governance
                  </NavLink>
               </li> */}
          <li>
            <NavLink
              to="/bridge"
              onClick={closeMenu}
              className={({ isActive }) =>
                (isActive ? "border-white" : "border-transparent") +
                " mx-4 block w-full border-b-2 pt-0.5 hover:border-white lg:w-min"
              }
            >
              Bridge
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/ignition"
              onClick={closeMenu}
              className={({ isActive }) =>
                (isActive ? "border-white" : "border-transparent") +
                " mx-4 block w-full border-b-2 pt-0.5 hover:border-white lg:hidden lg:w-min"
              }
            >
              Project Ignition
            </NavLink>
          </li>

          {/* <li>
                  <NavLink
                     to="https://stats.defiplaza.net/pools/radixplaza"
                     onClick={closeMenu}
                     className={({ isActive }) => (isActive ? 'border-white' : 'border-transparent') + ' block w-full lg:w-min whitespace-nowrap mx-4 pt-0.5 border-b-2 hover:border-white'}
                  >
                     Pair Analytics
                  </NavLink>
               </li> */}
        </ul>

        <div className="flex items-center">
          <NetworkSelector></NetworkSelector>

          <radix-connect-button />
        </div>
      </nav>
    </header>
  );
}